import React from "react";
import { FeedProps, useStatusFeed } from "./hooks";
import { Divider, Empty, Skeleton, Table } from "antd";

import LinkOutlined from '@ant-design/icons/LinkOutlined'
import FilterOutlined from '@ant-design/icons/FilterOutlined'
import MailOutlined from '@ant-design/icons/MailOutlined'

import './TableFeed.css'
import { processStatuses, Status, status } from "./common";
import { Link } from "react-router-dom";

export const TableFeed: React.FC<FeedProps> = ({ name, statuses }) => {
    const { loading, data } = useStatusFeed(name || '', statuses || []);
    const columns = [
        {
            title: "Actions",
            dataIndex: "character",
            render: (text: string, record: Status, index: number) => (
                <div style={{ width: 50, whiteSpace: 'nowrap', marginRight: 20 }}>
                    <a href={record.profile} target="_blank" rel="noreferrer noopener"><LinkOutlined /></a>
                    <Divider type="vertical" />
                    <Link to={`/c/${record.character}`}><FilterOutlined /></Link>
                    <Divider type="vertical" />
                    <a href={record.note} target="_blank" rel="noreferrer noopener"><MailOutlined /></a>
                </div>
            ),
            key: "actions",
        },
        {
            title: "Avatar",
            dataIndex: "avatar",
        },
        {
            title: "Character",
            dataIndex: "character",
        },
        {
            title: "Status",
            dataIndex: "status",
            render: (text: string, record: Status, index: number) => (<div className="status">{status(text)}</div>),
        },
        {
            title: "Message",
            dataIndex: "message",
        },

    ];

    return (
            <Skeleton loading={loading} avatar={false}>
                    <Table
                        rowKey={s => `${s.character}-${s.time}`}
                        className={"tanimate"}
                        rowClassName={() => "animate"}
                        dataSource={processStatuses(data ? data.statuses : [])}
                        columns={columns}
                        pagination={false}
                    >
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </Table>
            </Skeleton>
    )
};
