import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Card, Divider, Empty, Skeleton, Timeline } from "antd";
import { IconText, processStatuses } from "./common";
import { useStatusFeed } from "./hooks";
import TimeAgo from 'react-timeago';
import './CharacterFeed.css'
import { FListAvatar } from "./FListAvatar";
import { statusIcon } from "./elements/icons";
import { fetch_character } from "./utils/character-search";



export const CharacterFeed: React.FC = () => {
    const { character } = useParams() as any;
    const { loading, data } = useStatusFeed(character, []);
    const [timeline, setTimeline] = useState([<Empty />]);
    const [characterData, setCharacterData] = useState<any>({});
    const profileLink = `https://www.f-list.net/c/${character.toLowerCase()}`;
    const noteLink = `https://www.f-list.net/read_notes.php?send=${character.toLowerCase()}`;
    const timelineItem = (s: any) => (
        <Timeline.Item dot={statusIcon(s.status)} className="timeline">
            <span style={{ width: "50px", display: "inline-block" }}>{s.status}</span>
            <Divider type="vertical" />
            <span style={{ width: "100px", display: "inline-block" }}><TimeAgo date={s.time} /></span>
            <Divider type="vertical" />
            {s.message}
        </Timeline.Item>
    );

    useEffect(() => {
        const inner = async () => {
            const data = await fetch_character(character)
            setCharacterData(data)
        }
        inner().catch(error => {
            console.error(`[fetching character ${error.name}] ${error.message}`)
            setCharacterData({})
        })
    }, [])

    useEffect(() => {
        const elements = processStatuses(data?.statuses ?? []).map(timelineItem).reverse();
        setTimeline(elements);
    }, [data]);

    return <div>
        <h2>The Depraved Utterings of {character}</h2>
        <Card
            style={{ width: 500, marginTop: 16, marginBottom: 16 }}
            actions={[
                <a href={profileLink} rel="noopener noreferrer" target="_blank"><IconText type="link" text="F-List"
                    key="1" /></a>,
                <a href={noteLink} rel="noopener noreferrer" target="_blank"><IconText type="mail" text="Send Message"
                    key="2" /></a>,
            ]}
        >
            <Card.Meta
                avatar={
                    <FListAvatar character={character} size={100} />
                }
                title={character}
                description={<>
                    <div>Gender: {characterData.gender}</div>
                    <div>Species: {characterData.species}</div>
                    <div>Position: {characterData.orientation}</div>
                    <div>Occupation: {characterData.occupation}</div>
                </>}
            />
        </Card>
        <Skeleton loading={loading}>
            <Timeline
                mode="left"
                reverse={true}
                pending={true}
                children={timeline}
            />
        </Skeleton>
    </div>
};
