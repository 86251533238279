const MEILISEARCH_KEY = process.env.REACT_APP_MEILI_SEARCH_KEY
const MEILISEARCH_URL = process.env.REACT_APP_MEILI_SEARCH_URL

async function sha512(str: string) {
    const digest = await crypto.subtle.digest("SHA-512", new TextEncoder().encode(str))
    return Array.prototype.map.call(new Uint8Array(digest), x => (('00' + x.toString(16)).slice(-2))).join('')
}

export async function fetch_character(characterName: string, count: number = 0): Promise<any> {
    if (count >= 10) {
        return {}
    }
    const docId = await sha512(characterName)
    const response = await fetch(`https://sense-fstatus.stormweyr.dk/collections/flist-characters/documents/${docId}`, {
        method: 'GET',
        credentials: 'same-origin',
        referrerPolicy: 'same-origin',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'x-typesense-api-key': 'NhS0WY8zSvM4OBaVf32aErwLEMDuY2sA',
        } as any
    })
    if (response.status !== 200) {
        await new Promise(resolve => setTimeout(resolve, 2000))
        return await fetch_character(characterName, count++)
    }
    return response.json()
}
