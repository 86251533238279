import React, { useEffect, useState } from "react";
import { Empty, Radio, Select } from "antd";
import { EveryoneItemRenderer } from "./EveryBodyFeed";
import { ListFeed } from "./ListFeed";
import { TableFeed } from "./TableFeed";
import UnorderedListOutlined from '@ant-design/icons/UnorderedListOutlined';
import TableOutlined from '@ant-design/icons/TableOutlined'

const OPTIONS: { [k: string]: string } = {
    "crown": "Cookie!",
    "looking": "Looking",
    "away": "Away",
    "busy": "Busy",
    "online": "Online",
};

export const SelectFeedStyle: React.FC = () => {
    const [buttonState, setButtonState] = useState(localStorage.getItem('feedSelector') || 'list');
    const [statusTags, setStatusTags] = useState(localStorage.getItem('statusesWatched') || '');
    const [statusFilters, setStatusFilters] = useState<string[]>([]);
    const [content, setContent] = useState(<Empty />);

    useEffect(() => {
        setStatusFilters(statusTags ? statusTags.split(',') : [])
    }, [statusTags])

    useEffect(() => {
        localStorage.setItem("feedSelector", buttonState);
        localStorage.setItem("statusesWatched", statusTags.toString());

        switch (buttonState) {
            case "list":
                setContent(<ListFeed statuses={statusFilters} itemRenderer={EveryoneItemRenderer} />);
                break;
            case "table":
                setContent(<TableFeed statuses={statusFilters} />);
                break;
        }
    }, [buttonState, statusTags, statusFilters]);

    const filteredOptions = Object.keys(OPTIONS).filter(o => !statusFilters.includes(o));
    return (
        <div>
            <Radio.Group value={buttonState} onChange={e => setButtonState(e.target.value)} style={{ marginBottom: 10 }}>
                <Radio.Button value="list"><UnorderedListOutlined /></Radio.Button>
                <Radio.Button value="table"><TableOutlined /></Radio.Button>
            </Radio.Group>

            <Select
                mode="tags"
                placeholder="Status Filters"
                onChange={(e: string) => setStatusTags(e.toString())}
                style={{ marginLeft: "1em", minWidth: "10em" }}
                value={statusFilters as any}
            >
                {filteredOptions.map(key => (
                    <Select.Option key={key} value={key}>{OPTIONS[key]}</Select.Option>
                ))}
            </Select>

            {content}
        </div>
    )
};
