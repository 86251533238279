import React from "react";

export const About: React.FC = () => {
    return (
        <div>
            <h2>What is this F-Status thing even?</h2>
            <p>
                F-Status tracks live character statuses posted on the adult roleplaying website F-List, specifically
                the live chat portion of the site. Status messages are often used to seek roleplay opportunities or
                express the mood of the poster, whether this mood be positive or negative.
            </p>
            <p>
                This service is developed and maintained by Stormy Dragon (Serylis on F-List) as a tool for helping
                interested people find partners for the purposes of roleplay, or perhaps just to revel with fascination
                or disgust at the many many different ways we've come up with to stoke pleasure in ourselves and
                one another. Whatever your reason may be, we won't judge, just remember that this is all about
                entertainment and that all participants are consenting thinking feeling adults.
            </p>
            <p>The project takes a lot of time to develop and expand on, if you would like to support my efforts I
                have one of them <a href="https://www.patreon.com/stormydragon">Patreon accounts</a> where I can
                accept one time donations or if you feel that this project is worth a monthly allowance, that.
                Any such donations go toward server costs and future improvements.
            </p>
        </div>
    )
};
