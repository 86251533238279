import React from "react";
import { FListAvatar } from "./FListAvatar";
import { statusIcon } from "./elements/icons";

export type Status = {
    character: string
    message: React.ReactElement
    status: string
    statusmsg: string
    time: string
    avatar: React.ReactElement
    profile: string
    note: string
}

type IconProps = {
    type: string
    text: string
}

export function status(name: string): string {
    switch (name) {
        case "busy":
            return "Busy 💼";
        case "idle":
            return "Idle ⌛";
        case "dnd":
            return "DND 🔕";
        case "looking":
            return "Looking 🎣";
        case "crown":
            return "Cookie 🍪";
        case "away":
            return "Away 🧻";
        default:
        case "online":
            return "Online 😏";
    }
}

export const IconText: React.FC<IconProps> = ({ type, text }) => {
    const icon = statusIcon(type)
    return (
        <span>
            {icon}
            {text}
        </span>
    )
};

export const processStatuses = (statuses: Array<Status>): Status[] => {
    const profileLink = (s: Status) => `https://www.f-list.net/c/${s.character.toLowerCase()}`;
    const noteLink = (s: Status) => `https://www.f-list.net/read_notes.php?send=${s.character.toLowerCase()}`;

    return statuses.map(s => ({
        ...s,
        message: <span className="message" dangerouslySetInnerHTML={{ __html: s.statusmsg }} />,
        avatar: <FListAvatar character={s.character} size={24} />,
        profile: profileLink(s),
        note: noteLink(s),
    }));
};
