import {List} from "antd";
import {Link} from "react-router-dom";
import React from "react";
import {IconText, status} from "./common"
import {SelectFeedStyle} from "./FeedSelector";
import {FListAvatar} from "./FListAvatar";


export const EveryoneItemRenderer = (item: any) => {
    const profileLink = `https://www.f-list.net/c/${item.character.toLowerCase()}`;
    const noteLink = `https://www.f-list.net/read_notes.php?send=${item.character.toLowerCase()}`;
    const characterLink = `/c/${item.character}`;
    return (

        <List.Item
            actions={[
                <a href={profileLink} target="_blank" rel="noreferrer noopener"><IconText type="link" text="F-List" key="1"/></a>,
                <Link to={characterLink}><IconText type="filter" text={`Statuses from ${item.character}`}
                                                   key="2"/></Link>,
                <a href={noteLink} target="_blank" rel="noreferrer noopener"><IconText type="mail" text="Send Message" key="3"/></a>,
            ]}
        >
            <List.Item.Meta
                title={item.character}
                description={status(item.status)}
                avatar={<FListAvatar character={item.character} size={64}/>}
            />
            <span className="message" dangerouslySetInnerHTML={{__html: item.statusmsg}}/>
        </List.Item>
    );
};

export const EveryBodyFeed: React.FC = () => {
    return (
        <div>
            <h2>Utterings of Depravity</h2>
            <SelectFeedStyle/>
        </div>)
};
