import {HttpLink} from "apollo-link-http";
import {WebSocketLink} from "apollo-link-ws";
import {split} from "apollo-link";
import {getMainDefinition} from "apollo-utilities";
import ApolloClient from "apollo-client";
import {InMemoryCache} from "apollo-cache-inmemory";

const webLink = new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URL
});
const webSocketLink = new WebSocketLink({
    uri: process.env.REACT_APP_GRAPHQL_WS_URL || '',
    options: {reconnect: true}
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
    // split based on operation type
    ({query}) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
    },
    webSocketLink,
    webLink,
);

export const apolloClient = new ApolloClient({
    link,
    cache: new InMemoryCache()
});
