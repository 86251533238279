import React, { FunctionComponent } from 'react'
// Import components
import {
    InstantSearch,
    ClearRefinements,
    RefinementList,
    Configure,
    connectSearchBox,
    Pagination,
} from 'react-instantsearch-dom';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter'
import { connectHits } from 'react-instantsearch-dom';
import { Hit } from '@algolia/client-search';
import { FListAvatar } from '../FListAvatar';
import { Card, Layout, Typography, Row, Col, Input } from 'antd';

interface FListCharacter {
    character_fetishlistfave: string[]
    character_fetishlistyes: string[]
    character_name: string
    species: string
    gender: string
    id: string
    orientation: string
}

const gridStyle = {
    width: '25%',
    textAlign: 'center',
};

const CharacterHit: FunctionComponent<{ hit: Hit<FListCharacter> }> = ({ hit }) => {
    return (
        <Card title={
            <a href={`https://f-list.net/c/${hit.character_name}`}
                target='_blank'
                rel='noopener noreferrer'>
                {hit.character_name}
            </a>
        }>
            <Card.Grid style={gridStyle as any}>
                <FListAvatar character={hit.character_name} size={100} />
            </Card.Grid>
            <Card.Grid>
                <Typography>Species: {hit.species}</Typography>
            </Card.Grid>
            <Card.Grid>
                <Typography>Orientation: {hit.orientation}</Typography>
            </Card.Grid>
            <Card.Grid>
                <Typography>Gender: {hit.gender}</Typography>
            </Card.Grid>
        </Card>
    )
}

const CharacterHits = connectHits(({ hits }) => {
    let items;
    const output = []
    while (hits.length > 0) {
        items = hits.splice(0, 2)
        output.push(
            <Row gutter={[24, 24]}>
                {items.map(hit => <Col key={hit.objectID} span={12}><CharacterHit hit={hit as any} /></Col>)}
            </Row>
        )
    }
    return <>{output}</>
});

const SearchBox = connectSearchBox(({ currentRefinement, refine, isSearchStalled }) => {
    return (
        <Input.Search
            enterButton="Search"
            size={'large'}
            value={currentRefinement}
            loading={isSearchStalled}
            onChange={e => refine(e.currentTarget.value)} />
    )
})

const adapter = new TypesenseInstantSearchAdapter({
    server: {
        apiKey: 'NhS0WY8zSvM4OBaVf32aErwLEMDuY2sA',
        nodes: [
            { host: 'sense-fstatus.stormweyr.dk', port: "443", protocol: 'https' }
        ]
    },
    additionalSearchParameters: {
        queryBy: "character_name,species"
    }
})

const Search = () => {
    return (
        <InstantSearch indexName="flist-characters" searchClient={adapter.searchClient}>
            <Layout>
                <Layout>
                    <Layout.Sider theme="light">
                        <ClearRefinements />
                        <h2>Gender</h2>
                        <RefinementList attribute="gender" />
                        <h2>Orientation</h2>
                        <RefinementList attribute="orientation" />
                        <h2>Position</h2>
                        <RefinementList attribute="position" />
                        <h2>Species</h2>
                        <RefinementList attribute="species" showMore />
                        <h2>Fetish</h2>
                        <RefinementList attribute="character_fetishlistfave" showMore />
                        <Configure
                            hitsPerPage={12}
                            attributesToSnippet={["description:50"]}
                            snippetEllipsisText={"..."}
                        />
                    </Layout.Sider>
                    <Layout.Content>
                        <SearchBox />
                        <CharacterHits />
                        <Pagination />
                    </Layout.Content>
                </Layout>
            </Layout>
        </InstantSearch>
    )
}

export default Search
