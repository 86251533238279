import {useQuery} from "@apollo/react-hooks";
import React, {useEffect} from "react";
import {gql} from "apollo-boost";

const STATUS_QUERY = gql`
    query Statuses($count: Int = 100, $name: String = "", $status: [StatusKind] = []) {
        statuses(count: $count, status: $status, name: $name) {
            status
            statusmsg
            character
            empty
            time
        }
    }
`;
const STATUS_SUBSCRIPTION = gql`
    subscription Statuses($status: [StatusKind] = [], $name: String = "") {
        statuses(status: $status, name: $name) {
            status
            statusmsg
            character
            empty
            time
        }
    }
`;

export interface FeedProps {
    name?: string
    statuses?: string[]
}

export interface ListFeedProps extends FeedProps {
    itemRenderer: React.FC<{ time: string }>
}

export function useStatusFeed(name: string, $status: string[] = []) {
    const status = $status.map(s => s.toUpperCase());
    const {loading, data, subscribeToMore} = useQuery(STATUS_QUERY, {
        fetchPolicy: "network-only",
        variables: {count: 100, name: name || '', status}
    });

    useEffect(() => {
        return subscribeToMore({
            document: STATUS_SUBSCRIPTION,
            variables: {name: name || '', status},
            updateQuery: (prev, {subscriptionData}) => {
                if (!subscriptionData.data) return prev;
                const data = subscriptionData.data || {statuses: []};
                const prevStatuses = prev ? prev.statuses : [];
                return Object.assign({}, prev, {statuses: [data.statuses, ...prevStatuses].slice(0, 200)});
            }
        });
    }, [subscribeToMore, name, status]);

    return {loading, data};
}
