import React from "react";
import {List} from "antd";
import {ListFeedProps, useStatusFeed} from "./hooks";

export const ListFeed: React.FC<ListFeedProps> = ({name,statuses, itemRenderer}) => {
    const {loading, data} = useStatusFeed(name || '', statuses || []);

    return <List
        rowKey={row => row.time}
        bordered={true}
        itemLayout="vertical"
        dataSource={!loading && data ? data.statuses : []}
        loading={loading}
        renderItem={itemRenderer}>
    </List>
};
