import React from "react";

import SearchOutlined from '@ant-design/icons/SearchOutlined'
import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined'
import StopOutlined from '@ant-design/icons/StopOutlined'
import CrownOutlined from '@ant-design/icons/CrownOutlined'
import MinusCircleOutlined from '@ant-design/icons/MinusCircleOutlined'


export function statusIcon(status: string): React.ReactElement {
    switch (status) {
        case "away":
            return <SearchOutlined/>;
        case "idle":
            return <QuestionCircleOutlined/>;
        case "dnd":
            return <StopOutlined/>;
        case "crown":
            return <CrownOutlined/>;
        case "busy":
            return <MinusCircleOutlined/>;
        case "looking":
            return <SearchOutlined/>;
        default:
        case "online":
            return <SearchOutlined/>;
    }
}
