import React from 'react';
import './App.css';
import {Link, Route, Switch, useLocation} from 'react-router-dom';
import {Layout, Menu} from "antd";
import {ApolloProvider} from '@apollo/react-hooks';

import 'antd/dist/antd.min.css'
import {About} from "./About";
import {EveryBodyFeed} from "./EveryBodyFeed";
import {CharacterFeed} from "./CharacterFeed";
import {apolloClient} from "./graphql_client";
import ReactGA from "react-ga";
import Search from './instant-search/Search';


const {Header, Content, Footer} = Layout;


ReactGA.initialize('UA-13099763-3');

function usePageViews() {
    const location = useLocation();
    React.useEffect(()=> {
        ReactGA.set({page: location.pathname});
        ReactGA.pageview(location.pathname);
    }, [location]);
}

const App: React.FC = () => {
    usePageViews();

    return (
        <ApolloProvider client={apolloClient}>
            <Layout className="layout">
                <Header>
                    <div className="logo"/>
                    <Menu theme="dark" mode="horizontal" defaultSelectedKeys={["1"]} style={{lineHeight: '64px'}}>
                        <Menu.Item key="1"><Link to="/">Main Feed</Link></Menu.Item>
                        <Menu.Item key="2"><Link to="/search">Search</Link></Menu.Item>
                        <Menu.Item key="3"><Link to="/about">About FStatus</Link></Menu.Item>
                        
                    </Menu>
                </Header>
                <Content style={{padding: "0 50px"}}>
                    <div style={{background: "white", padding: 24, minHeight: 280}}>
                        <Switch>
                            <Route path="/c/:character"><CharacterFeed/></Route>
                            <Route path="/about"><About/></Route>
                            <Route path="/search"><Search/></Route>
                            <Route path="/"><EveryBodyFeed/></Route>
                        </Switch>
                    </div>
                </Content>
                <Footer style={{textAlign: "center"}}>Stormweyr ©2020 Created by Stormy Dragon</Footer>
            </Layout>
        </ApolloProvider>
    );
};

export default App;
